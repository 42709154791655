@import '../../../../scss/theme-bootstrap';

$gnav-signup-max-width: 380px;

.site-email-signup,
.site-sms-signup {
  min-height: 41px;
  width: 80%;
  margin: 0 auto;
  @include breakpoint($medium-up) {
    max-width: $gnav-signup-max-width;
    margin: 0;
  }
  &__title {
    font-family: 'La Mer Text';
    font-size: 20px;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  &__subtitle {
    text-align: center;
    font-style: italic;
  }
  &__copy {
    text-align: left;
    display: none;
    width: 80%;
    margin: 0 auto;
    @include breakpoint($medium-up) {
      width: auto;
      margin: 0;
    }
  }
  &__messages {
    text-align: left;
  }
  &__message {
    &--error {
      color: $color-error;
    }
    &--success {
      color: green;
    }
  }
  &__fields--email,
  &__fields--sms {
    @include pie-clearfix;
    border-bottom: 1px solid $color-sand;
    margin-bottom: 15px;
    @include breakpoint($medium-up) {
      width: 60%;
    }
  }
  input[type='email'].site-email-signup__field,
  input[type='tel'].site-email-signup__field,
  input[type='text'].site-email-signup__field {
    display: block;
    float: left;
    margin-bottom: 1em;
    padding-bottom: 0;
    background: none;
    width: 80%;
    border: none;
    padding: 0;
    margin: 0;
    color: $color-grey;
    &.error {
      color: $color-error;
    }
  }
  &__terms-conditions {
    display: none;
    position: relative;
    width: 80%;
    margin: 0 auto;
    @include breakpoint($medium-up) {
      width: auto;
      margin: none;
    }
    &__checkbox,
    &__more {
      margin-bottom: 20px;
    }
    .label p {
      text-indent: 25px;
      margin-top: -1.8em;
    }
  }
  input[type='submit'].site-email-signup__submit {
    border: $color-sand 1px solid;
    color: $color-sand;
  }
  &__success {
    padding-top: 8px;
    color &-header {
      text-transform: uppercase;
    }
    &-offer {
      line-height: 1.5em;
      &__code {
        text-transform: uppercase;
      }
    }
    &-terms {
      margin-top: 1em;
      margin-bottom: 2em;
    }
    &-cta {
      margin-bottom: 2em;
      .button--medium {
        min-width: 172px;
      }
    }
  }
  .site-email-signup__field,
  .site-email-signup__field:focus {
    color: $color-sand;
  }
  &__submit_arrow_right {
    display: block;
    height: 25px;
    top: -70px;
    position: relative;
    float: right;
    min-width: 0;
    background: none;
    border: 0;
    cursor: pointer;
    .icon {
      height: 22px;
      padding: 3px 3px 3px 11px;
      margin-bottom: 3px;
      width: 34px;
      fill: white;
    }
    input[type='submit'].site-email-signup__submit {
      display: none;
    }
  }
}
